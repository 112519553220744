<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item
        :to="{ path: '/checkCommonApply2/checkCommonApply21' }"
        >审核申请</el-breadcrumb-item
      >
      <el-breadcrumb-item>申请列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 申请列表 -->
    <el-card class="righttable right-lay">
      <div class="right-up">
        <i class="el-icon-tickets"></i>
        <span>组织申请列表</span>
        <el-table
          :data="listData"
          border
          stripe
          style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="uname"
            label="申请人"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="申请类型"
            prop="goupType"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="info" light v-if="scope.row.groupType === 2"
                >组织</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            label="组织名称"
            prop="oname"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="phone"
            label="申请人电话"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="application_status"
            sortable
            label="操作"
            align="center"
            min-width="150"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.application_status === 2"
                >已同意</el-tag
              >
              <el-tag
                type="warning"
                v-else-if="scope.row.application_status === 3"
                >已驳回</el-tag
              >
              <el-tag
                type="warning"
                v-else-if="scope.row.application_status === 4"
                >已解绑</el-tag
              >
              <div v-if="scope.row.application_status === 1">
                <el-button
                  type="success"
                  size="small"
                  @click="handleAgree(scope.row)"
                  >批准</el-button
                >
                <el-button
                  type="warning"
                  size="small"
                  @click="handleReject(scope.row)"
                  >驳回</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            background
            @size-change="handleOrgSizeChange"
            @current-change="handleOrgCurrentChange"
            layout="total, sizes, prev, pager, next,jumper"
            :current-page.sync="formInlineOrg.pageNum"
            :page-size="formInlineOrg.pageSize"
            :page-sizes="[5, 10, 15]"
            :total="orgTotal"
          ></el-pagination>
        </div>
      </div>
      <div class="right-down">
        <i class="el-icon-tickets"></i>
        <span>课程申请列表</span>
        <el-table
          :data="couListData"
          border
          stripe
          style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column
            label="序号"
            type="index"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="uname"
            label="申请人"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="申请类型"
            prop="goupType"
            min-width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="primary" light v-if="scope.row.groupType === 1"
                >课程</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            label="课程名称"
            prop="name"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="week"
            label="周次"
            min-width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="section"
            label="节数"
            min-width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="application_status"
            sortable
            label="操作"
            align="center"
            min-width="150"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.application_status === 2"
                >已同意</el-tag
              >
              <el-tag
                type="warning"
                v-else-if="scope.row.application_status === 3"
                >已驳回</el-tag
              >
              <el-tag
                type="warning"
                v-else-if="scope.row.application_status === 4"
                >已解绑</el-tag
              >
              <div v-if="scope.row.application_status === 1">
                <el-button
                  type="success"
                  size="small"
                  @click="handleAgree(scope.row)"
                  >批准</el-button
                >
                <el-button
                  type="warning"
                  size="small"
                  @click="handleReject(scope.row)"
                  >驳回</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            background
            @size-change="handleCouSizeChange"
            @current-change="handleCouCurrentChange"
            layout="total, sizes, prev, pager, next,jumper"
            :current-page.sync="formInlineCou.pageNum"
            :page-size="formInlineCou.pageSize"
            :page-sizes="[5, 10, 15]"
            :total="couTotal"
          ></el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  data() {
    return {
      listData: [], //组织申请列表
      couListData: [], //课程申请列表
      oid: '', //组织id
      // 分页
      formInlineOrg: {
        pageNum: 1,
        pageSize: 5
      },
      formInlineCou: {
        pageNum: 1,
        pageSize: 5
      },
      tid: null,
      orgTotal: 0,
      couTotal: 0,
      uid: '',
      reasonForm: {
        reason: ''
      },
      //记录本条数据
      data: {}
    }
  },
  created() {
    this.oid = this.$route.query.oid
    this.getList()
    this.getCouList()
  },
  mounted() {},
  methods: {
    // 实现组织分页
    // 更改了每页大小
    handleOrgSizeChange(val) {
      this.formInlineOrg.pageNum = 1
      this.formInlineOrg.pageSize = val
      this.getList()
    },
    // 更改了第几页
    handleOrgCurrentChange(val) {
      this.formInlineOrg.pageNum = val
      this.getList()
    },
    // 实现课程分页
    // 更改了每页大小
    handleCouSizeChange(val) {
      this.formInlineCou.pageNum = 1
      this.formInlineCou.pageSize = val
      this.getCouList()
    },
    // 更改了第几页
    handleCouCurrentChange(val) {
      this.formInlineCou.pageNum = val
      this.getCouList()
    },
    //获取申请记录表
    async getList() {
      const { data: res } = await this.$http.get('/org/applyhistory', {
        params: {
          oid: this.oid,
          pageNum: this.formInlineOrg.pageNum,
          pageSize: this.formInlineOrg.pageSize
        }
      })
      if (res.code == 200) {
        this.listData = res.data.map(e => {
          return {
            ...e,
            groupType: 2
          }
        })
        this.orgTotal = res.total
      }
    },
    //获取课程的申请列表
    async getCouList() {
      const { data: res } = await this.$http.get('/cou/couapplyhistory', {
        params: {
          oid: this.oid,
          pageNum: this.formInlineCou.pageNum,
          pageSize: this.formInlineCou.pageSize
        }
      })
      if (res.code == 200) {
        this.couListData = res.data.map(e => {
          return {
            ...e,
            groupType: 1
          }
        })
        this.couTotal = res.total
      }
    },
    //处理同意操作
    async handleAgree(row) {
      const confirmMeaasge =
        row.groupType === 1
          ? '此操作将同意此课程的申请, 是否继续?'
          : '此操作将同意此用户加入您的组织, 是否继续?'

      this.$confirm(confirmMeaasge, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let endpoint = ''
          let params = {}
          if (row.groupType === 1) {
            endpoint = '/cou/coupassapply'
            params = { coid: row.coid }
          } else {
            endpoint = '/org/passapply'
            params = { soid: row.soid }
          }
          const { data: res } = await this.$http.post(
            endpoint,
            qs.stringify(params)
          )
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '已同意!'
            })
            if (row.groupType === 1) {
              this.getCouList()
            } else {
              this.getList()
            }
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    //处理驳回操作
    async handleReject(row) {
      const confirmMeaasge =
        row.groupType === 1
          ? '确定拒绝此课程的申请吗？'
          : '确定拒绝此用户加入您的组织吗？'
      this.$confirm(confirmMeaasge, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let endpoint = ''
          let data = {}
          if (row.groupType === 1) {
            endpoint = '/cou/courejectapply'
            data = { coid: row.coid }
          } else {
            endpoint = '/org/rejectapply'
            data = { soid: row.soid }
          }
          const { data: res } = await this.$http.post(
            endpoint,
            qs.stringify(data)
          )
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '已驳回!'
            })
            if (row.groupType === 1) {
              this.getCouList()
            } else {
              this.getList()
            }
          } else {
            this.$message({
              type: 'warning',
              message: '驳回失败'
            })
          }
        })
        .catch(() => {
          this.$message.info('已取消操作')
        })
    }
  }
}
</script>

<style scoped></style>
